<template>
  <div>
    <Loading v-if="cargando" />
    <!-- Modal -->
    <div
      id="modal_aside_right"
      class="modal fixed-left fade"
      tabindex="-1"
      role="dialog"
    >
      <div class="modal-dialog modal-dialog-aside" role="document">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h5 class="modal-title text-white">Movimientos Sellos</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group col-md-12">
              <label for="origen">Sitio Origen</label>
              <v-select
                :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                v-model="sitioOrigen"
                placeholder="Sitio Origen"
                label="nombre"
                :options="listasForms.sitiosOrigenes"
                :filterable="false"
                @search="buscarSitioOrigen"
                @input="selectSitioOrigen()"
              ></v-select>
            </div>
            <div class="form-group col-md-12">
              <label for="destino">Sitio Destino</label>
              <v-select
                :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                v-model="sitioDestino"
                placeholder="Sitio Destino"
                label="nombre"
                :options="listasForms.sitiosDestinos"
                :filterable="false"
                @search="buscarSitioDestino"
                @input="selectSitioDestino()"
              ></v-select>
            </div>
            <div class="row justify-content-center">
              <div class="form-group">
                <button
                  v-if="!hasDescription"
                  class="btn mr-1"
                  :class="
                    rango
                      ? 'bg-gradient-success shadow'
                      : 'alert-default-success'
                  "
                  @click="seleccionar('rango')"
                >
                  <i class="fas fa-ruler-horizontal"></i>
                  Rango
                </button>
                <button
                  class="btn ml-1"
                  :class="
                    hasDescription
                      ? 'bg-gradient-success shadow'
                      : 'alert-default-success'
                  "
                  @click="seleccionar('unitario')"
                >
                  <i class="far fa-money-bill-alt"></i>
                  Sello unitario
                </button>
              </div>
            </div>
            <div class="col-lg-12" v-if="!hasDescription && rango == false">
              <div class="form-group">
                <label for="cantidad_sellos">Cantidad de Sellos</label>
                <input
                  type="number"
                  class="form-control form-control-sm"
                  id="cantidad_sellos"
                  placeholder="Cantidad Sellos"
                  v-model="form.cantidad_sellos"
                  :class="
                    $v.form.cantidad_sellos.$invalid ? 'is-invalid' : 'is-valid'
                  "
                  @input="validarCantidadSellos"
                />
              </div>
            </div>
            <div class="col-lg-12" v-if="hasDescription">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="sello_unitario"
                  placeholder="sello Unitario"
                  v-model.trim="form.sello_unitario"
                  :class="
                    $v.form.sello_unitario.$invalid ? 'is-invalid' : 'is-valid'
                  "
                />
              </div>
            </div>
            <div class="col-lg-12" v-if="!hasDescription && rango == true">
              <div class="form-group">
                <label for="sello_inicial">Sello Inicial</label>

                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="sello_inicial"
                  placeholder="Número"
                  v-model="form.sello_inicial"
                  :class="
                    $v.form.sello_inicial.$invalid ? 'is-invalid' : 'is-valid'
                  "
                />
              </div>
            </div>
            <div class="col-lg-12" v-if="!hasDescription && rango == true">
              <div class="form-group" v-show="!$v.form.sello_inicial.$invalid">
                <label for="sello_final">Sello Final</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="sello_final"
                  placeholder="Sello Final"
                  v-model="form.sello_final"
                  :class="
                    $v.form.sello_final.$invalid ? 'is-invalid' : 'is-valid'
                  "
                  @change="validarRango()"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <button
                  type="button"
                  class="btn btn-dark"
                  style="cursor: pointer"
                  @click="sellosOrigen"
                  v-show="!$v.form.$invalid && !rango && buscar"
                >
                  Buscar Sellos
                  <i class="fab fa-searchengin"></i>
                </button>
              </div>
            </div>
            <div class="row pt-2">
              <div class="col-md-12">
                <div class="card-body p-0">
                  <!-- @change="findFilterBar($event)" -->
                  <table
                    class="
                      table table-bordered table-striped table-hover table-sm
                    "
                  >
                    <tbody>
                      <tr v-for="item in sellos" :key="item.id">
                        <td>
                          {{ item.numero }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              @click="trasladar = false"
            >
              Cerrar
            </button>
            <button
              type="button"
              v-show="!$v.form.$invalid && trasladar"
              class="btn btn-primary"
              @click="trasladoSellos()"
            >
              Trasladar Sellos
            </button>
          </div>
        </div>
      </div>
      <!-- modal-bialog .// -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required, minLength } from "vuelidate/lib/validators";
import vSelect from "vue-select";
import Loading from "../../../../components/Loading.vue";

export default {
  name: "SelloMovimiento",
  components: {
    vSelect,
    Loading,
  },
  data() {
    return {
      form: {
        sitio_origen_id: null,
        sitio_destino_id: null,
        sitio_origen: null,
        sitio_destino: null,
        bloque_sitio_origen_id: null,
        bloque_sitio_destino_id: null,
        sello_inicial: null,
        sello_final: null,
        cantidad_sellos: null,
      },
      filtros: {
        sitio_origen_id: null,
        sello_unitario: null,
      },
      arraySellos: [],
      sitioOrigen: [],
      sitioDestino: [],
      listasForms: {
        sitiosOrigenes: [],
        sitiosDestinos: [],
      },
      sellos: {},
      hasDescription: false,
      rango: false,
      cargando: false,
      trasladar: false,
      buscar: true,
    };
  },
  validations() {
    if (!this.hasDescription && !this.rango) {
      return {
        form: {
          sitio_origen_id: {
            required,
          },
          sitio_destino_id: {
            required,
          },
          cantidad_sellos: {
            required,
          },
        },
      };
    } else if (!this.hasDescription && this.rango) {
      return {
        form: {
          sitio_origen_id: {
            required,
          },
          sitio_destino_id: {
            required,
          },
          sello_inicial: {
            required,
          },
          sello_final: {
            required,
          },
        },
      };
    } else {
      return {
        form: {
          sitio_origen_id: {
            required,
          },
          sitio_destino_id: {
            required,
          },
          sello_unitario: {
            required,
          },
        },
      };
    }
  },
  methods: {
    trasladoSellos() {
      this.cargando = true;
      this.form.sellos = this.sellos;
      if (!this.$v.form.$invalid) {
        axios({
          method: "POST",
          url: "/api/hidrocarburos/sellos/saveTraslado",
          data: this.form,
        })
          .then((response) => {
            if (response.data && response.data.mensaje) {
              if (response.data.numero_sellos) {
                this.$swal({
                  icon: "error",
                  title: response.data.mensaje,
                  html: response.data.numero_sellos
                    ? "El sello<br><br>" +
                      response.data.numero_sellos +
                      "<br><br>ya se encuentra en el destino"
                    : "Los sellos<br><br>" +
                      response.data.numero_sellos +
                      "<br><br>ya se encuentran en el destino",
                  showConfirmButton: true,
                });
                this.cargando = false;
              } else {
                this.$swal({
                  icon: "error",
                  title: response.data.mensaje,
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
                this.cargando = false;
              }
            } else {
              this.limpiarDatos();
              this.$parent.getIndex();
              this.$refs.closeModal.click();
              this.cargando = false;
              this.trasladar = false;
              this.$swal({
                icon: "warning",
                title: "El traslado se efectuo exitosamente",
                text: "Tenga en cuenta que este traslado debe ser autorizado.",
                showConfirmButton: true,
              });
            }
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.cargando = false;
          });
      }
    },

    sellosOrigen() {
      this.filtros.sitio_origen_id = null;
      if (this.sitioOrigen) {
        this.filtros.sitio_origen_id = this.sitioOrigen.id;
        this.filtros.cantidad_sellos = this.form.cantidad_sellos;
        this.filtros.sello_unitario = this.form.sello_unitario;
      }
      axios
        .get("/api/hidrocarburos/sellos/sellosMover", {
          params: this.filtros,
        })
        .then((response) => {
          this.sellos = response.data;
          if (this.sellos.mensaje) {
            this.$swal({
              icon: "error",
              title: this.sellos.mensaje,
              toast: true,
              position: "bottom-end",
              showConfirmButton: false,
              timer: 8000,
              timerProgressBar: true,
            });
          }
          this.trasladar = true;
        });
    },

    async buscarSitioOrigen(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        let url = "api/admin/sitios/lista?sitio=" + search;
        await axios
          .get(url)
          .then(async function(response) {
            let respuesta = await response.data;
            me.listasForms.sitiosOrigenes = respuesta;
            //me.form.sitio_origen_id = me.listasForms.sitiosOrigenes.id;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    async buscarSitioDestino(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        let url = "api/admin/sitios/lista?sitio=" + search;
        await axios
          .get(url)
          .then(async function(response) {
            let respuesta = await response.data;
            me.listasForms.sitiosDestinos = respuesta;
            //me.form.sitio_destino_id = me.listasForms.sitiosDestinos.id;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    selectSitioOrigen() {
      this.form.sitio_origen_id = "";
      this.form.sitio_origen = "";
      this.form.bloque_sitio_origen_id = "";
      if (this.sitioOrigen) {
        this.form.sitio_origen_id = this.sitioOrigen.id;
        this.form.sitio_origen = this.sitioOrigen.nombre;
        this.form.bloque_sitio_origen_id = this.sitioOrigen.bloque_id;
      } else {
        this.form.sitio_origen_id = null;
        this.form.sitio_origen = null;
      }
    },

    selectSitioDestino() {
      this.form.sitio_destino_id = "";
      this.form.sitio_destino = "";
      this.form.bloque_sitio_destino_id = "";
      if (this.sitioDestino) {
        this.form.sitio_destino_id = this.sitioDestino.id;
        this.form.sitio_destino = this.sitioDestino.nombre;
        this.form.bloque_sitio_destino_id = this.sitioDestino.bloque_id;
      } else {
        this.form.sitio_destino_id = null;
        this.form.sitio_destino = null;
      }
    },

    seleccionar(dato) {
      this.form.cantidad_sellos = null;
      this.form.sello_inicial = null;
      this.form.sello_final = null;
      this.sellos = {};
      this.trasladar = true;
      if (dato == "rango") {
        this.rango = this.rango == true ? false : true;
      }
      if (dato == "unitario") {
        this.hasDescription = this.hasDescription == true ? false : true;
      }
    },

    validarRango() {
      if (
        parseInt(this.form.sello_inicial) >= parseInt(this.form.sello_final)
      ) {
        this.form.sello_final = null;
        this.$swal({
          icon: "error",
          title: "El sello inicial debe ser menor al sello final",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    },

    validarCantidadSellos() {
      if (this.form.cantidad_sellos > 1000) {
        this.buscar = false;
        this.$swal({
          icon: "warning",
          title: "Cantidad de Sellos Invalida",
          text: "El cantidad máxima de sellos a trasladar es 1000.",
          showConfirmButton: true,
        });
      } else {
        this.buscar = true;
      }
    },

    limpiarDatos() {
      this.form.sitio_origen_id = null;
      this.form.sitio_destino_id = null;
      this.form.sitio_origen = null;
      this.form.sitio_destino = null;
      this.form.bloque_sitio_origen_id = null;
      this.form.bloque_sitio_destino_id = null;
      this.form.sello_inicial = null;
      this.form.sello_final = null;
      this.form.cantidad_sellos = null;
      this.filtros.sitio_origen_id = null;
      this.filtros.sello_unitario = null;
      this.sitioOrigen = [];
      this.sitioDestino = [];
      this.sellos = {};
      this.hasDescription = false;
      this.rango = false;
    },
  },
};
</script>
<style>
.modal .modal-dialog-aside {
  width: 300px;
  max-width: 80%;
  height: 100%;
  margin: 0;
  transform: translate(2);
  transition: transform 0.2s;
}
.modal .modal-dialog-aside .modal-content {
  height: inherit;
  border: 1;
  border-radius: 1;
}
.modal .modal-dialog-aside .modal-content .modal-body {
  overflow-y: auto;
}
.modal.fixed-left .modal-dialog-aside {
  margin-left: auto;
  transform: translateX(100%);
}
.modal.fixed-right .modal-dialog-aside {
  margin-right: auto;
  transform: translateX(-100%);
}
.modal.show .modal-dialog-aside {
  transform: translateX(0);
}
</style>
